import { pageview } from '@/client/gtm';
import '@/client/youtubeBanner.js';
import '@/styles/index.css';
import assetUrl from '@/utilities/assetUrl';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script.js';
import { useEffect } from 'react';

function MyApp({ Component, pageProps }: AppProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const title = pageProps.title;
  const description = pageProps.description || '';

  useEffect(() => {
    if (pathname) {
      pageview(pathname);
    }
  }, [pathname, searchParams]);

  const websiteUrl = (() => {
    return typeof pageProps.canonical?.path === 'string'
      ? `https://www.lotto.de${pageProps.canonical?.path}`
      : `https://www.lotto.de${pageProps.path || ''}`;
  })();

  const imageUrl = (() => {
    return typeof pageProps.image?.url === 'string'
      ? pageProps.image?.url
      : 'https://www.lotto.de/assets/images/logo-main.webp';
  })();

  const noindex = (() => {
    return pageProps.noindex === true;
  })();

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0,viewport-fit=cover"
        />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={websiteUrl} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:description" content={description} />
        {noindex && <meta name="robots" content="noindex" />}
        <link rel="canonical" href={websiteUrl} />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/assets/favicon/apple-touch-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/assets/favicon/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/assets/favicon/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/assets/favicon/apple-touch-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/favicon/apple-touch-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon/favicon-16x16.png"
        />
        <link
          rel="mask-icon"
          href="/assets/favicon/safari-pinned-tab.svg"
          color="#ca0813"
        />
        <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
        <link rel="manifest" href="/assets/manifest.json" />
        <meta name="application-name" content="LOTTO.de" />
        <meta name="apple-mobile-web-app-title" content="LOTTO.de" />
        <meta name="msapplication-TileColor" content="#ca0813" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="apple-touch-startup-image"
          href="/assets/images/splash/launch-640x1136.png"
          media="(width: 320px) and (height: 568px) and (-webkit-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/assets/images/splash/launch-750x1294.png"
          media="(width: 375px) and (height: 667px) and (-webkit-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/assets/images/splash/launch-1242x2148.png"
          media="(width: 414px) and (height: 736px) and (-webkit-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/assets/images/splash/launch-1125x2436.png"
          media="(width: 375px) and (height: 812px) and (-webkit-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/assets/images/splash/launch-1536x2048.png"
          media="(min-width: 768px) and (max-width: 1024px) and (-webkit-min-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/assets/images/splash/launch-1668x2224.png"
          media="(min-width: 834px) and (max-width: 834px) and (-webkit-min-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="/assets/images/splash/launch-2048x2732.png"
          media="(min-width: 1024px) and (max-width: 1024px) and (-webkit-min-pixel-ratio: 2) and (orientation: portrait)"
        />
      </Head>
      <Component key={pageProps.path} {...pageProps} />
      {pageProps.path === '/datenschutzerklaerung' && (
        <Script src={assetUrl(`/assets/privacy.js`)} />
      )}
      <Script src={assetUrl(`/assets/piwik/utils.js`)} />
      <Script src={assetUrl(`/assets/sw-unregister.js`)} />
    </>
  );
}

export default MyApp;
