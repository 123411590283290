const INITIAL_STATE = {
  allowedVideos: [],
};

const state = INITIAL_STATE;

const setState = (newState) => {
  Object.assign(state, newState);
  localStorage.setItem('yt-banner-state', JSON.stringify(state, null, 2));
};

const PIWIK_KEY_YOUTUBE = 'custom_consent'; // yeah weird name

export const isPiwikAllowed = () => {
  let consents;
  if (
    typeof window !== 'undefined' &&
    // @ts-ignore
    window.ppms &&
    // @ts-ignore
    window.ppms.cm &&
    // @ts-ignore
    window.ppms.cm.api
  ) {
    // @ts-ignore
    window.ppms.cm.api(
      'getComplianceSettings',
      function (result) {
        consents = result.consents;
      },
      function () { }
    );
  }
  if (
    consents &&
    consents[PIWIK_KEY_YOUTUBE] &&
    // @ts-ignore
    consents[PIWIK_KEY_YOUTUBE].status === 1
  ) {
    return true;
  }
  return false;
};

const setPiwikAllowed = (value) => {
  const numericValue = value ? 1 : 0;
  // @ts-ignore
  if (!window.ppms || !window.ppms.cm) {
    return;
  }
  // @ts-ignore
  window.ppms.cm.api(
    'setComplianceSettings',
    { consents: { [PIWIK_KEY_YOUTUBE]: { status: numericValue } } },
    function (result) { },
    function () { }
  );
};

const maybeShowBanner = (event) => {
  if (isPiwikAllowed()) {
    //console.info('[debug] piwik allows youtube cookies');
    return;
  }
  //console.info("[debug] piwik doesn't allow youtube cookies");
  // @ts-ignore
  if (state.allowedVideos.includes(event.detail.url)) {
    return;
  }
  event.preventDefault();

  const id = `yt-${Math.round(Math.random() * 9999999)}`;

  const $Text1 = document.createElement('p');
  $Text1.textContent = 'Wir brauchen ihre Einwilligung';
  $Text1.style.color = 'rgb(84, 76, 76)';
  $Text1.style.textAlign = 'center';
  $Text1.style.fontSize = '20px';
  $Text1.style.fontWeight = '500';
  if (window.innerWidth < 500) {
    $Text1.style.fontSize = '15px';
  }

  const $Text2 = document.createElement('p');
  $Text2.textContent =
    'Dieses Video wird von YouTube bereitgestellt. YouTube ist ein Produkt von Google Ireland Limited. Wir weisen darauf hin, dass YouTube möglicherweise personenbezogene Daten an Google Inc. in den USA übermittelt und daher ein mit der EU vergleichbares Datenschutzniveau nicht gewährleistet ist. Mit dem Klick auf den Button „Akzeptieren“ geben Sie uns Ihre Einwilligung für eine Weiterleitung zu YouTube.';
  $Text2.style.color = 'rgb(84, 76, 76)';
  $Text2.style.textAlign = 'center';
  $Text2.style.lineHeight = '1.5';
  if (window.innerWidth < 500) {
    $Text2.style.fontSize = '11px';
  }

  const $Button = document.createElement('button');
  $Button.className = 'Button Button--primary';
  $Button.style.alignSelf = 'center';
  $Button.textContent = 'Akzeptieren';
  $Button.style.marginTop = '0.2rem';
  $Button.onclick = () => {
    $Banner.remove();
    setPiwikAllowed(true);
    // @ts-ignore
    $Parent.querySelector('button').focus();
  };

  const $Banner = document.createElement('div');
  $Banner.style.padding = '1rem 2rem';
  $Banner.style.position = 'absolute';
  $Banner.style.zIndex = '123456';
  $Banner.style.top = '50%';
  $Banner.style.left = '50%';
  $Banner.style.transform = `translate(-50%, -50%)`;
  $Banner.tabIndex = -1;
  $Banner.style.width = '400px';
  $Banner.style.background = 'white';
  $Banner.style.display = 'flex';
  $Banner.style.flexDirection = 'column';
  if (window.innerWidth < 500) {
    $Banner.style.width = '350px';
    $Banner.style.top = `calc(50% - 20px)`;
  }
  $Banner.append($Text1, $Text2, $Button);

  const $Target = event.detail.event.target;
  const $Parent = $Target.closest('.Video');
  $Parent.append($Banner);
  $Banner.focus();
};

const getInitialState = () => {
  const cachedItem = localStorage.getItem('yt-banner-state');
  if (!cachedItem) {
    return INITIAL_STATE;
  }
  try {
    const state = JSON.parse(cachedItem);
    if ('allowedVideos' in state) {
      return state;
    }
  } catch { }
  return INITIAL_STATE;
};

const ytBanner = () => {
  Object.assign(state, getInitialState());
  window.addEventListener('lotto:willPlayVideo', maybeShowBanner);
};

if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_PRODUCTION_INSTANCE !== 'true'
) {
  ytBanner();
}
